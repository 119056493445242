import { Component, OnInit, Input } from '@angular/core';
import { EChartsOption } from 'echarts';
import * as echarts from 'echarts';
import { AreaGraph, AreaGraphView } from 'src/app/model/vertical-views';

const CANVAS_HEIGHT_PX = 230;
const PADDING_BOTTOM_CANVAS_PX = 25;
const PADDING_TOP_CANVAS_PX = 45;
const COMPUTED_CANVAS_HEIGHT_PX =
  CANVAS_HEIGHT_PX - PADDING_BOTTOM_CANVAS_PX - PADDING_TOP_CANVAS_PX;
const MINCHANGE_PX_OFFSET = 32;

@Component({
  selector: 'app-stacked-area-chart',
  templateUrl: './stacked-area-chart.component.html',
  styleUrls: ['./stacked-area-chart.component.scss'],
})
export class StackedAreaChartComponent implements OnInit {
  @Input() isLoading = true;
  @Input() selectedColumnIndex?: number;
  @Input() set graph(value: AreaGraph) {
    this._graph = value;
    this.initGraphOption();
  }
  public myChart: any;
  public options: EChartsOption = {};
  public mergeOptions: EChartsOption = {};

  private _graph: AreaGraph = {} as AreaGraph;
  private baseOptions: any = {
    tooltip: {
      trigger: 'axis',
      axisPointer: {
        type: 'cross',
        label: {
          backgroundColor: '#6a7985',
        },
      },
      formatter: (params: any) => {
        // Default ECharts tooltip formatter
        let tooltipContent = params
          .map((param: any) => {
            // Use the default formatter and append % symbol to the value
            return `${param.marker} ${param.seriesName}: ${param.value}%`;
          })
          .join('<br/>');  // Join the series info with a line break
  
        return `${params[0].name}<br/>${tooltipContent}`;  // Include the axis value (e.g., 'Q1', '1', etc.)
      },
    },
    legend: {
      show: false, // Hide the legend if desired
      data: [],
    },
    grid: {
      left: '3%',  
      right: '9%',  
      top: '3%',    
      bottom: '3%', 
      containLabel: true,
    },
    xAxis: [
      {
        type: 'category',
        boundaryGap: false,
        data: [],
        axisLine: {
          show: false, // Hide full Line
        },
        xisTick: {
          show: false, // Hide Ticks
        },
      },
    ],
    yAxis: [
      {
        type: 'value',
        min: 0,
        max: 100, 
        splitLine: {
          show: false,  // Hide grid lines
        },
        axisTick: {
          show: false, // Disable Y-axis ticks completely
        },
        axisLabel: {
          formatter: (value: number, index: number, values: any[]) => {
            // Only show labels for the first and last values
            if (value === 0 || value === 100) {
              return `${value}%`; 
            }
            return ''; 
          },
          fontSize: 10,  // Reduce the font size for Y-axis labels
        },
      },
    ],
    series: null,
  };

  get graph() {
    return this._graph;
  }

  constructor() {}

  ngOnInit(): void {
    this.initGraphOption();
  }
  public onChartInit(ec: any): void {
    this.myChart = ec;
    this.initChartEvents(ec);
  }

  private initChartEvents(chartInstance: any): void {
    chartInstance.getZr().on('click', (params: any) => {
      const pointInPixel = [params.offsetX, params.offsetY];
      const pointInGrid = chartInstance.convertFromPixel('grid', pointInPixel);
    });
  }

  private initGraphOption(): void {
    this.baseOptions.xAxis[0].data = this.mapXAxisData(this.graph.data);
    this.baseOptions.legend.data = this.generateLegend(this.graph.data);
    this.baseOptions.series = this.mapCurrentData(this.graph.data);
    this.options = { ...this.baseOptions };
    console.log(this.options);
  }

  private mapXAxisData(graphData: any[]): String[] {
    if (graphData?.[0]?.data?.length == 4) {
      return ['Q1', 'Q2', 'Q3', 'Q4'];
    } else {
      return ['1', '2', '3', '4', '5', '6', '7', '8', '9', '10', '11', '12'];
    }
  }
  private mapCurrentData(graphData: any[]): any[] {
    return graphData.map((item, index) => this.mapCurrentDataItem(item, index));
  }
  private arrayOfColors = [
    '#918EF4',
    '#EB88DB',
    '#E3E5BC',
    '#00BBF9',
    '#00F5D4',
  ];
  private mapCurrentDataItem(item: any, index: any): any {
    return {
      name: item.colName,
      type: 'line',
      stack: 'Total',
      areaStyle: {
        color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [
          {
            offset: 0,
            color: this.arrayOfColors[index]
          },
          {
            offset: 1,
            color: 'transparent'
          }]),
        opacity: '0.5',
      },
      lineStyle: {
        color: this.arrayOfColors[index],
        width: 2,
      },
      symbol: 'circle', 
      symbolSize: 6,   
      itemStyle: {
        color: this.arrayOfColors[index], 
      },
      data: item.data,
    };
  }
  private generateLegend(graphData: any[]): String[] {
    return graphData.map((item) => item.colName);
  }
}
