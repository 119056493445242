<ng-container [ngSwitch]="graph?.type || null">
    <app-vertical-view *ngSwitchCase="graphType.Sales" [graphData]="graph"></app-vertical-view>
    <app-vertical-view *ngSwitchCase="graphType.OI" [graphData]="graph"></app-vertical-view>
    <app-vertical-view *ngSwitchCase="graphType.EBITDA" [graphData]="graph"></app-vertical-view>
    <app-vertical-view *ngSwitchCase="graphType.GROSS" [graphData]="graph"></app-vertical-view>
    <app-vertical-view *ngSwitchCase="graphType.P_SALES_BY_TERRITORY" [isStackedArea]='true' [graphData]="graph"></app-vertical-view>
    <app-eps *ngSwitchCase="graphType.EPS" [graphData]="graph"></app-eps>
    <app-net-income *ngSwitchCase="graphType.NI" [graphData]="graph"></app-net-income>
    <app-sbu *ngSwitchCase="graphType.SBU" [graphData]="graph"></app-sbu>
    <app-horizontal-view *ngSwitchCase="graphType.TOP_PRODUCTS" [graphData]="graph"></app-horizontal-view>
    <app-horizontal-view *ngSwitchCase="graphType.TOP_CUSTOMERS" [graphData]="graph" [withGestures]="false"></app-horizontal-view>
    <app-commodities-specialties-view *ngSwitchCase="graphType.Commodities" [graphData]="graph"></app-commodities-specialties-view>
    <app-fcf *ngSwitchCase="graphType.FCF" [graphData]="graph"></app-fcf>
    <app-territory-graph *ngSwitchCase="graphType.TERRITORY" [territoryBarData]="graph"></app-territory-graph>
    <app-potash-prices *ngSwitchCase="graphType.POTASH_PRICES" [graphData]="graph"></app-potash-prices>
    <app-icl-fcf *ngSwitchCase="graphType.ICL_FCF" [graphData]="graph" ></app-icl-fcf>
    <app-production-kmt *ngSwitchCase="graphType.PRODUCTION_KTM" [graphData]="graph"></app-production-kmt>
    <app-financial-kpi *ngSwitchCase="graphType.KPI" [graphData]="graph" ></app-financial-kpi>
    <app-potash-avg-price *ngSwitchCase="graphType.POTASH_AVG" [graphData]="graph"></app-potash-avg-price>
    <app-production-division *ngSwitchCase="graphType.PRODUCTION_DIVISION" [graphData]="graph"></app-production-division>
    <app-capex *ngSwitchCase="graphType.CAPEX" [graphData]="graph"></app-capex>
    <div *ngSwitchDefault></div>
</ng-container>