import { NgModule, CUSTOM_ELEMENTS_SCHEMA } from '@angular/core';
import { NgxEchartsModule } from 'ngx-echarts';
import { AppRoutingModule } from './app-routing.module';
import { BrowserModule } from '@angular/platform-browser';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';

import { AppComponent } from './app.component';
import { SplashComponent } from './splash/splash.component';
import { PageNotFoundComponent } from './page-not-found/page-not-found.component';
import { PostAuthorizationComponent } from './post-authorization/post-authorization.component';
import { AuthenticationInterceptor } from './interceptors/authentication.interceptor';
import { DashboardComponent } from './components/dashboard/dashboard.component';
import { StocksPanelComponent } from './components/stocks-panel/stocks-panel.component';
import { LoadingPageComponent } from './components/loading-page/loading-page.component';
import { VerticalViewComponent } from './components/vertical-view/vertical-view.component';
import { BatteryGraphComponent } from './components/battery-graph/battery-graph.component';
import { GraphsAnchorsComponent } from './components/graphs-anchors/graphs-anchors.component';
import { SectionLayoutComponent } from './components/section-layout/section-layout.component';
import { DivisionsHeaderComponent } from './components/divisions-header/divisions-header.component';
import { VerticalBarGraphComponent } from './components/vertical-bar-graph/vertical-bar-graph.component';
import { BusinessUnitNavigationComponent } from './components/business-unit-navigation/business-unit-navigation.component';
import { NetIncomeComponent } from './components/net-income/net-income.component';
import { SlickCarouselModule } from 'ngx-slick-carousel';
import { EpsComponent } from './components/eps/eps.component';
import { EpsFooterComponent } from './components/eps-footer/eps-footer.component';
import { SwitchGraphsComponent } from './components/switch-graphs/switch-graphs.component';
import { VerticalViewSlideComponent } from './components/vertical-view-slide/vertical-view-slide.component';
import { EpsSlideComponent } from './components/eps-slide/eps-slide.component';
import { OiBySbuComponent } from './components/oi-by-sbu/oi-by-sbu.component';
import { HorizontalBarGraphComponent } from './components/horizontal-bar-graph/horizontal-bar-graph.component';
import { HorizontalBarComponent } from './components/horizontal-bar/horizontal-bar.component';
import { HorizontalMoreInfoComponent } from './components/horizontal-more-info/horizontal-more-info.component';
import { HorizontalViewComponent } from './components/horizontal-view/horizontal-view.component';
import { SbuComponent } from './components/sbu/sbu.component';
import { SbuSlideComponent } from './components/sbu-slide/sbu-slide.component';
import { BatteryComponent } from './components/battery/battery.component';
import { TerritoryGraphComponent } from './components/territory-graph/territory-graph.component';
import { TerritorySlideComponent } from './components/territory-slide/territory-slide.component';
import { CommoditiesSpecialtiesViewComponent } from './components/commodities-specialties-view/commodities-specialties-view.component';
import { ComSpecSlideComponent } from './components/com-spec-slide/com-spec-slide.component';
import { PiGraphComponent } from './components/pi-graph/pi-graph.component';
import { FinancialDataTableComponent } from './components/financial-data-table/financial-data-table.component';
import { CommSpecCompareGraphComponent } from './components/comm-spec-compare-graph/comm-spec-compare-graph.component';
import { PotashPricesComponent } from './components/potash-prices/potash-prices.component';
import { PricesTableComponent } from './components/prices-table/prices-table.component';
import { MultiBarChartComponent } from './components/multi-bar-chart/multi-bar-chart.component';
import { HandleValueSignForMultiBarPipe } from './pipes/handle-value-sign-for-multi-bar.pipe';
import { ProductionKmtComponent } from './components/production-kmt/production-kmt.component';
import { ProductionKmtCellComponent } from './components/production-kmt-cell/production-kmt-cell.component';
import { ProductionKmtSlideComponent } from './components/production-kmt-slide/production-kmt-slide.component';
import { FinancialKpiComponent } from './components/financial-kpi/financial-kpi.component';
import { HalfDoughuntGraphComponent } from './components/half-doughunt-graph/half-doughunt-graph.component';
import { PotashAvgPriceComponent } from './components/potash-avg-price/potash-avg-price.component';
import { LineBarGraphComponent } from './components/line-bar-graph/line-bar-graph.component';
import { RealTimeProductionComponent } from './components/real-time-production/real-time-production.component';
import { SparkChartComponent } from './components/spark-chart/spark-chart.component';
import { ErrorInterceptor } from './interceptors/error.interceptor';
import { ErrorPageComponent } from './error-page/error-page.component';
import { ProductionDivisionComponent } from './components/production-division/production-division.component';
import { ProductionDivisionSlideComponent } from './components/production-division-slide/production-division-slide.component';
import { CapexComponent } from './components/capex/capex.component';
import { ICLFcfComponent } from './components/icl-fcf/icl-fcf.component';
import { FcfComponent } from './components/fcf/fcf.component';
import { FcfSlideComponent } from './components/fcf-slide/fcf-slide.component';
import { NumberCounterComponent } from './components/number-counter/number-counter.component';
import { FormatNegativeNumberPipe } from './pipes/format-negative-numbers.pipe';
import { LoaderComponent } from './components/loader/loader.component';

import { environment } from 'src/environments/environment';
import { PublicClientApplication, InteractionType } from "@azure/msal-browser";
import { MsalModule, MsalRedirectComponent, MsalGuard, MsalInterceptor } from "@azure/msal-angular";
import { StackedAreaChartComponent } from './components/stacked-area-chart/stacked-area-chart.component';

  const isIE =
  window.navigator.userAgent.indexOf("MSIE ") > -1 ||
  window.navigator.userAgent.indexOf("Trident/") > -1;

const msalConfig = {...environment.adalConfig,authority:environment.adalConfig.issuer, redirectUri: window.location.origin + '/postauth'};
@NgModule({
  declarations: [
    AppComponent,
    SplashComponent,
    PageNotFoundComponent,
    DashboardComponent,
    DivisionsHeaderComponent,
    GraphsAnchorsComponent,
    SectionLayoutComponent,
    PostAuthorizationComponent,
    VerticalBarGraphComponent,
    StocksPanelComponent,
    LoadingPageComponent,
    BatteryGraphComponent,
    BusinessUnitNavigationComponent,
    VerticalViewComponent,
    EpsComponent,
    EpsFooterComponent,
    NetIncomeComponent,
    SwitchGraphsComponent,
    VerticalViewSlideComponent,
    EpsSlideComponent,
    HorizontalBarGraphComponent,
    HorizontalBarComponent,
    HorizontalMoreInfoComponent,
    HorizontalViewComponent,
    OiBySbuComponent,
    SbuComponent,
    SbuSlideComponent,
    BatteryComponent,
    CommoditiesSpecialtiesViewComponent,
    ComSpecSlideComponent,
    PiGraphComponent,
    FinancialDataTableComponent,
    CommSpecCompareGraphComponent,
    TerritoryGraphComponent,
    TerritorySlideComponent,
    PotashPricesComponent,
    PricesTableComponent,
    MultiBarChartComponent,
    HandleValueSignForMultiBarPipe,
    FormatNegativeNumberPipe,
    ProductionKmtComponent,
    ProductionKmtCellComponent,
    ProductionKmtSlideComponent,
    FinancialKpiComponent,
    HalfDoughuntGraphComponent,
    PotashAvgPriceComponent,
    LineBarGraphComponent,
    RealTimeProductionComponent,
    SparkChartComponent,
    ErrorPageComponent,
    ProductionDivisionComponent,
    ProductionDivisionSlideComponent,
    CapexComponent,
    ICLFcfComponent,
    FcfComponent,
    FcfSlideComponent,
    NumberCounterComponent,
    LoaderComponent,
    StackedAreaChartComponent,
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    HttpClientModule,
    SlickCarouselModule,
    BrowserAnimationsModule,
    NgxEchartsModule.forRoot({
      echarts: () => import('echarts'),
    }),
    MsalModule.forRoot(
      new PublicClientApplication({
        auth: {
          clientId: msalConfig.clientId,
          authority: msalConfig.authority,
          redirectUri: msalConfig.redirectUri,
          navigateToLoginRequestUrl:false
        },
        cache: {
          cacheLocation: "localStorage",
          storeAuthStateInCookie: isIE,
        },
      }),
      {
        interactionType: InteractionType.Redirect, // Msal Guard Configuration
        authRequest: {
          scopes: ["user.read"],
        },
      },
      {
        interactionType: InteractionType.Redirect, // MSAL Interceptor Configuration
        protectedResourceMap: new Map([
          ["https://graph.microsoft.com/v1.0/me", ["user.read"]]
        ]),
      }
    ),
  ],
  providers: [
    { provide: HTTP_INTERCEPTORS, useClass: AuthenticationInterceptor, multi: true },
    { provide: HTTP_INTERCEPTORS, useClass: ErrorInterceptor, multi: true },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: MsalInterceptor,
      multi: true,
    },
    MsalGuard
  ],
  bootstrap: [AppComponent, MsalRedirectComponent],
  schemas: [ CUSTOM_ELEMENTS_SCHEMA ]
})
export class AppModule { }
